import React from "react"
import LayoutDefault from "../components/layouts/default"

import TechtunesAds from "../components/parts/body/techtunes-invest"


export default () => (

  <LayoutDefault>
    <TechtunesAds></TechtunesAds>
  </LayoutDefault>

)
